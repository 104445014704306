import { useState, useRef } from "react";

import styled from "styled-components";
import { motion } from "framer-motion";
import html2canvas from "html2canvas";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-inline: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-block: 20px;
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
  & img {
    max-width: 200px;
  }
  & h2 {
    color: rgb(255, 200, 0);
    font-weight: 600;
  }
`;

const PhoneFrame = styled.div`
  width: 90vw;
  height: auto;
  max-width: 470px;
  aspect-ratio: 9/12;
  border-radius: 13px;
  border: 2px solid white;
  box-shadow: rgba(255, 255, 255, 0.25) 0px 0px 20px;
`;

const Screen = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background: #202020;
  cursor: pointer;
  user-select: none;
  text-align: center;
  overflow: hidden;
  text-shadow: 0px 0px 15px #0000005e;
  border-radius: 10px;
  & span {
    font-size: 30px;
  }
  & strong {
    font-size: 20px;
    font-weight: 600;
    padding-inline: 80px;
  }
`;

const SmallFrame = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 100px;
  height: auto;
  aspect-ratio: 9/12;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
  border: 3px solid white;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  text-align: center;
  z-index: 2;
  & small {
    font-size: 13px;
    font-weight: 600;
    text-shadow: 0px 0px 5px #000;
    padding-inline: 20px;
  }
`;

const Username = styled.small`
  position: absolute;
  padding: 10px;
  bottom: 10px;
  right: 10px;
  color: white;
  font-size: 15px;
  font-weight: 600;
  text-shadow: 0px 0px 5px #000, 0px 0px 5px #000, 0px 0px 5px #000;
  z-index: 2;
`;

const Editable = styled.small`
  font-size: 15px;
  font-weight: 600;
  &:focus {
    border: 0;
    outline: 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const Button = styled.button`
  all: unset;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  margin: auto;
  padding: 0.5em 1.5em;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  color: black;
  background-color: white;
  transition: all 0.15s;
  &:disabled {
    background-color: #818181;
    cursor: not-allowed;
  }
`;

const SecondaryButton = styled(Button)`
  color: rgb(255, 200, 0);
  background-color: rgba(255, 200, 0, 0.25);
  backdrop-filter: blur(10px);
`;

const AbsoluteImage = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
`;

const FadeOnView = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          delay: props.delayInSeconds || 0,
        },
      }}
    >
      {props.children}
    </motion.div>
  );
};

function App() {
  const [smallPicture, setSmallPicture] = useState<string>("");
  const [bigPicture, setBigPicture] = useState<string>("");

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const html2canvasRef = useRef<HTMLDivElement | undefined>(null);
  const smallRef = useRef<HTMLInputElement | undefined>(null);
  const bigRef = useRef<HTMLInputElement | undefined>(null);

  const handleSmallChange = async ({ target }) => {
    let file: Blob | MediaSource = target.files[0];
    setSmallPicture(URL.createObjectURL(file));
  };

  const handleBigChange = async ({ target }) => {
    let file: Blob | MediaSource = target.files[0];
    setBigPicture(URL.createObjectURL(file));
  };

  function switchImages() {
    setSmallPicture(bigPicture);
    setBigPicture(smallPicture);
  }

  function download() {
    if (!html2canvasRef) return;
    setIsDownloading(true);
    html2canvas(html2canvasRef?.current, {
      useCORS: true,
      windowWidth: 1920,
      windowHeight: 1080,
      scale: 5,
      backgroundColor: "hsl(222, 60%, 5%)",
    }).then(function (canvas) {
      const dataURL = canvas.toDataURL();
      const link = document.createElement("a");
      link.download = "Your BeReal";
      link.href = dataURL;
      link.click();
      setIsDownloading(false);
    });
  }

  return (
    <Container>
      <FadeOnView>
        <StyledLogo>
          <img src="/bereal-logo.png" alt="" width="100%" />
          <h2>Free Image Generator</h2>
        </StyledLogo>
      </FadeOnView>

      <FadeOnView delayInSeconds={0.5}>
        <Content>
          <PhoneFrame>
            <Screen
              ref={html2canvasRef}
              onClick={(e) => {
                bigRef.current.click();
                e.stopPropagation();
              }}
            >
              <input
                ref={bigRef}
                type="file"
                name="file"
                accept="image/png, image/jpg, image/jpeg"
                multiple={false}
                onChange={handleBigChange}
                style={{ display: "none" }}
              />
              <SmallFrame
                onClick={(e) => {
                  smallRef.current.click();
                  e.stopPropagation();
                }}
              >
                <input
                  ref={smallRef}
                  type="file"
                  name="file"
                  accept="image/png, image/jpg, image/jpeg"
                  multiple={false}
                  onChange={handleSmallChange}
                  style={{ display: "none" }}
                />
                {smallPicture ? (
                  <AbsoluteImage
                    style={{
                      backgroundImage: `url(${smallPicture})`,
                      zIndex: 3,
                    }}
                  />
                ) : (
                  <small>Tap to Change</small>
                )}
              </SmallFrame>
              <Username onClick={(e) => e.stopPropagation()}>
                BeRe.al/
                <Editable contentEditable spellCheck={false}>
                  TapToChange
                </Editable>
              </Username>
              {bigPicture ? (
                <AbsoluteImage
                  style={{ backgroundImage: `url(${bigPicture})` }}
                />
              ) : (
                <>
                  <span>🖼️</span>
                  <strong>Tap to Change</strong>
                </>
              )}
            </Screen>
          </PhoneFrame>
        </Content>
        <ButtonGroup>
          <Button onClick={() => download()} disabled={isDownloading}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 20 20"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Download
          </Button>
          <SecondaryButton onClick={() => switchImages()}>
            Switch
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 20 20"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"></path>
            </svg>
          </SecondaryButton>
        </ButtonGroup>
      </FadeOnView>
    </Container>
  );
}

export default App;
